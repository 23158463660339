import { template as template_69d729f3d01f4c6983ad6d428f958218 } from "@ember/template-compiler";
const FKControlMenuContainer = template_69d729f3d01f4c6983ad6d428f958218(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
