import { template as template_f272214571c1401b8783cdd4fbd0ce3c } from "@ember/template-compiler";
const FKLabel = template_f272214571c1401b8783cdd4fbd0ce3c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
