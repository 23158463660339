import { template as template_a279e1994d18409ab1ac38062bfc011e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_a279e1994d18409ab1ac38062bfc011e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
