import { template as template_35b5f6d23082409f9f1d258bd17b9dd2 } from "@ember/template-compiler";
const WelcomeHeader = template_35b5f6d23082409f9f1d258bd17b9dd2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
