import { template as template_cdd37c38c4e14208991a64410d67964e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_cdd37c38c4e14208991a64410d67964e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
