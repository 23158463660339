import { template as template_856f47ff7888403f98997a4565d4b765 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_856f47ff7888403f98997a4565d4b765(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
